import React from "react";
import {Link} from "react-router-dom";
import axios from 'axios'
import Swal from 'sweetalert2';
import { useState, useEffect, Component } from 'react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules'; 
import { Swiper, SwiperSlide } from 'swiper/react';
import Helmet from 'react-helmet';
import { Audio } from 'react-loader-spinner'
import Select from 'react-select'
import countryFile from './Country';
import checkNumber from './CheckMobileNumber';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import jQuery from 'jquery';


export default class Home extends Component {
    
    constructor(){
        super();
        this.state={
          notifications:[],
          blogs:[],
          courses:[],
          programs:[],
          countries: [], 
          states: [],
          stateValue:'',
          mobile:''
        }
      }

      selectCountry = (val) => {
        this.setState({ stateValue: '' });
        const state_arr = countryFile.s_a[val.key].split("|"); 
        const stateArray = [];
        stateArray.push({
          value: '', 
          label: 'Select'
        });
        for (var i = 0; i < state_arr.length; i++) {
          stateArray.push({
          value: state_arr[i], 
          label: state_arr[i]
          });
        }
        this.setState({ states: stateArray });
    
        var Country = val.value;
        let check = checkNumber.checkNumber(Country,this.state.mobile);
        if(check === 2){
          this.setState({mobile:''});
        }
      }
      
        selectRegion = (val) => {
          if(val.value !== '' && val.value !== null){
            this.setState({stateValue:val});
          } else {
            this.setState({stateValue:''});
          }
        }
        updateMobile = (value) => {
          this.setState({mobile:value});
        }
     
      async componentDidMount() {
        jQuery('.more-btn').click(function(e) {
          e.preventDefault();
          jQuery(this).text(function(i, t) {
            return t == 'Read less' ? 'Read more' : 'Read less';
          }).prev('.more-content').slideToggle()
        });

        const countryArray = [];
        var country1 = countryFile.country_arr;
        countryArray.push({
          value: '', 
          label: 'Select',
          key:[0]
        });
        for (var i = 0; i < country1.length; i++) {
          countryArray.push({
            value: country1[i], 
            label: country1[i],
            key:[i+1]
          });
        }
        this.setState({countries:countryArray});
        this.getList();
      }
      //API CALLS
      getList=()=>{
        this.getUser();
      }

      notificationDate(par){
        const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        const today = new Date(par);
        const month = months[today.getMonth()];
        const year = today.getFullYear();
        const date = today.getDate();
        return month+ ' ' +date+', ' +year;
      }

      async getUser(){
        let usersData=await axios.get('/home-page-data')
            .then(res=>{
                this.setState({
                    notifications:res.data.notifications,
                    blogs:res.data.blogs,
                    courses:res.data.courses,
                    programs:res.data.programs,
                    colleges:res.data.colleges
                },()=>{
                    // console.log(this.state)
                })
            })
            .catch(err=>{
                console.log(err);
            })
      }

      resetForm(){
        document.getElementById('home-page-middle-form').reset();
      }

      handleSubmit(event) {
        event.preventDefault();
        jQuery("#overlay").fadeIn(300);　
        const formData = new FormData(event.target);
        axios.post('/store-enquiry', formData)
        .then(({data})=>{
            event.target.reset()
            jQuery('#enquiry').modal('hide');
            if(data.status === 200){
                Swal.fire({
                    icon:"success",
                    text:data.message
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            } else if(data.status === 201){
                Swal.fire({
                    text:'We have already received an enquiry from this mobile number or email.',
                    icon:"error"
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            } else {
                Swal.fire({
                    text:'Error please try again later!',
                    icon:"error"
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            }
        })
      }

    render(){
        const {notifications}=this.state;
        const {blogs}=this.state;
        const {courses}=this.state;
        const {programs}=this.state;
        const {colleges}=this.state;
        const { countries, states, stateValue , mobile} = this.state;
        return (
          <div id="content-wrapper" className="site-content-wrapper">
            <Helmet>
                    <title>Top BCA Colleges in Bangalore | Get Direct admission for BCA colleges | Admissions Open 2023-24</title>
                    <meta name="description" content="Get direct admissions to Top BCA Colleges in Bangalore without any hassle. We provide support services from counselling to admissions enrolment at top colleges. Call now!"/>
                    <meta name="keywords" content="best bca colleges in Bangalore, top bca colleges in Bangalore, direct admissions for bca course, admissions at bca colleges in Bangalore, get direct admissions in top colleges in bangalore."/>  
            </Helmet>
            <div id="content" className="site-content ">
             <div id="content-inner" className="site-content-inner ">
                <div id="primary" className="content-area">
                  <main id="main" className="site-main">
                      <article id="post-2133" className="post-2133 page type-page status-publish hentry">
                         <header className="single-entry-header tm-hide">
                            <h2 className="entry-title">BCA Colleges</h2>
                         </header>
                         <div className="entry-content">
                            <div className="vc_row-full-width vc_clearfix"></div>
                            <div data-vc-full-width="true" data-vc-full-width-init="false" className="tm-row wpb_row vc_row-fluid tm-responsive-custom-55915355 vc_row-has-fill break-991-colum tm-zindex-0 tm-bgimage-position-right_bottom hidden-sm hidden-md hidden-lg visible-xs">
                                <div className="vc_row container">
                                  <div className="wpb_column tm-column vc_column_container vc_col-sm-5 tm-zindex-0 margin-15px-991-colum">
                                      <div className="vc_column-inner  ">
                                        <div className="wpb_wrapper">
                                            <div className="res-slider-form">
                                              <div className="widget">
                                                  <h4 className="widget-title text-center">Quick Enquiry</h4>
                                                  <p className="text-center">Apply now to get admission for BCA</p>
                                                  <div className="widget-content">
                                                    <form onSubmit={this.handleSubmit}>
                                                        <div className="row">
                                                          <div className="col-xs-12">
                                                              <div className="text-block">
                                                                <input type="text" name="name" placeholder="Name *" required/> 
                                                              </div>
                                                          </div>
                                                          <div className="col-xs-12">
                                                              <div className="text-block">
                                                                <input type="email" name="email" placeholder="Email *" required/> 
                                                              </div>
                                                          </div>
                                                          <div className="col-xs-12">
                                                              <div className="text-block">
                                                                <input type="text" value={mobile} onChange={e => this.updateMobile(e.target.value)} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}  className="EnquiryMobile" name="phone" placeholder="Phone *" required/> 
                                                              </div>
                                                          </div>
                                                          <div className="col-xs-12">
                                                              <div className="text-block">
                                                                <input type="text" name="course" placeholder="Course *" required/>
                                                                <input type="hidden" name="source" value="2"/> 
                                                              </div>
                                                          </div>
                                                          <div className="col-xs-12 pad-5">
                                                            <div className="text-block">
                                                                <Select options={countries} name="country"  placeholder="Select Country" required onChange={this.selectCountry} />
                                                            </div>
                                                          </div>
                                                          <div className="col-xs-12 pad-5">
                                                            <div className="text-block">
                                                                <Select options={states} value={stateValue} name="state" className="select-state" placeholder="Select State" required onChange={this.selectRegion} />
                                                            </div>
                                                          </div>
                                                          <div className="col-xs-12 text-center">
                                                              <input type="submit" value="Submit" className="btn submit"/> 
                                                          </div>
                                                        </div>
                                                    </form>
                                                  </div>
                                              </div>
                                            </div>
                                        </div>
                                      </div>
                                  </div>
                                </div>
                            </div>

                            <div data-vc-full-width="true" data-vc-full-width-init="false" className="tm-row wpb_row vc_row-fluid vc_custom_1567684117259 tm-responsive-custom-55915355 vc_row-has-fill break-991-colum tm-zindex-0 tm-bgimage-position-right_bottom">
                              <div className="vc_row container">
                                <div className="wpb_column tm-column vc_column_container vc_col-sm-5 tm-zindex-0 margin-15px-991-colum">
                                    <div className="vc_column-inner  ">
                                      <div className="wpb_wrapper">
                                          <div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid tm-responsive-custom-10018428 tm-zindex-0">
                                            <div className="wpb_column tm-column-inner vc_column_container vc_col-sm-12 vc_col-has-fill tm-zindex-0">
                                                <div className="vc_column-inner vc_custom_1567594802767 mb10">
                                                  <div className="wpb_wrapper">
                                                      <div className="wpb_single_image wpb_content_element vc_align_left  vc_custom_1567595065320">
                                                        <figure className="wpb_wrapper vc_figure">
                                                            <div className="vc_single_image-wrapper vc_box_border_grey">
                                                              <img src="/assets/img/about-home.jpg" className="vc_single_image-img attachment-full lozad" alt="Top BCA Colleges in Bangalore" src="/assets/img/about-home.jpg" data-loaded="true"/>
                                                            </div>
                                                        </figure>
                                                      </div>
                                                  </div>
                                                </div>
                                            </div>
                                          </div>
                                      </div>
                                    </div>
                                </div>
                                <div className="wpb_column tm-column vc_column_container vc_col-sm-7 tm-zindex-0 margin-15px-991-colum">
                                    <div className="vc_column-inner tm-responsive-custom-12751771">
                                      <div className="wpb_wrapper">
                                          <div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
                                            <section className="tm-vc_cta3-container tm-sepcolor-default">
                                                <div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
                                                  <div className="tm-vc_cta3_content-container">
                                                      <div className="tm-vc_cta3-content">
                                                        <header className="tm-vc_cta3-content-header tm-wrap">
                                                            <div className="tm-vc_cta3-headers tm-wrap-cell">
                                                              <h2 className="tm-custom-heading ">What is BCA - Bachelor of Computer Applications?</h2>
                                                            </div>
                                                            <div className="heading-seperator"><span></span></div>
                                                        </header>
                                                        <div className="tm-cta3-content-wrapper">
                                                            <p>Bachelor of Computer Application (BCA) is a 3-years  undergraduate course that is divided into 6-semesters. The course basically delves into the world of working on computers. The BCA is one of the popular courses among the students after engineering and medical. The students are trained in detail on the working of computers and their softwares. With the rapid growth of the IT industry in India, the demand for computer professionals is increasing every day. The increasing growth in the tech industry has created a lot of opportunities for the skilled computer graduates.</p>
                                                            <p>Bachelor of Computer Application (BCA) is one of the most chosen popular courses among the students who are interested to make a fruitful career in the information technology (IT) field. Graduating from one of the <b>top BCA colleges in Bangalore</b> can significantly enhance students' career prospects. The institutions have a strong track record of producing successful graduates who are highly sought-after by leading IT companies. Many colleges also offer placement assistance and facilitate connections with industry professionals, providing students with valuable networking opportunities.</p>
                                                            <div className="more-content hm-more-content">
                                                              <p>The BCA course is for those who are interested in the computer science field and wish to gain knowledge in various aspects of computer applications like networking, software engineering, hardware engineering, website designing, etc. The course provides and demonstrates in-depth knowledge in technical areas of industrial computing systems, which easily leads to skills required to meet the challenges of the IT field. The course trains the students on different and important programming languages such as Java, Python, Oracle, C, C++, C #, SQL which are huge in demand in the tech industry.</p>
                                                            </div>
                                                            <a href="#" className="btn more more-btn">Read more</a>
                                                        </div>
                                                      </div>
                                                  </div>
                                                </div>
                                            </section>
                                          </div>
                                      </div>
                                    </div>
                                </div>
                                <div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-zindex-0 margin-15px-991-colum">
                                    <div className="vc_column-inner tm-responsive-custom-12751771">
                                      <div className="wpb_wrapper">
                                          <hr/>
                                          <div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
                                            <section className="tm-vc_cta3-container tm-sepcolor-default">
                                                <div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
                                                  <div className="tm-vc_cta3_content-container">
                                                      <div className="tm-vc_cta3-content">
                                                        <header className=" tm-wrap">
                                                            <div className="tm-vc_cta3-headers tm-wrap-cell">
                                                              <h3 className="tm-custom-heading mt20">Top BCA Programs in Bangalore</h3>
                                                            </div>
                                                        </header>
                                                      </div>
                                                  </div>
                                                </div>
                                            </section>
                                          </div>
                                          <div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
                                          
                                          {programs && programs.length > 0 && programs.map((programloop, index) => {
                                            return (
                                              <div className="wpb_column tm-column-inner vc_column_container vc_col-sm-4 tm-zindex-0" key={`p${index}`}>
                                                <div className="vc_column-inner  ">
                                                  <div className="wpb_wrapper">
                                                    <ul className="m-0 tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
                                                        {programloop && programloop.length > 0 && programloop.map((program, index2) => {
                                                          return (
                                                            <li key={`pr${program.id}`}>
                                                              <i className="tm-skincolor fa fa-arrow-circle-right"></i> 
                                                              <span className="tm-list-li-content"><b>
                                                                <Link to={`/programs/${program.slug}`}>{program.course}</Link></b></span>
                                                            </li>
                                                          )
                                                        })}
                                                    </ul>
                                                  </div>
                                                </div>
                                              </div>
                                            )
                                          })}
																						
										 </div>
                                      </div>
                                    </div>
                                </div>
                                <div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-zindex-0 margin-15px-991-colum">
                                    <div className="vc_column-inner tm-responsive-custom-12751771">
                                      <div className="wpb_wrapper">
                                          <div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
                                            <section className="tm-vc_cta3-container tm-sepcolor-default">
                                                <div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
                                                  <div className="tm-vc_cta3_content-container">
                                                      <div className="tm-vc_cta3-content">
                                                        <header className=" tm-wrap">
                                                            <div className="tm-vc_cta3-headers tm-wrap-cell">
                                                              <h3 className="tm-custom-heading">Duration</h3>
                                                              <p>The BCA is a full time 3-years professional undergraduate course that is divided into 6-semesters.</p>
                                                            </div>
                                                        </header>
                                                      </div>
                                                  </div>
                                                </div>
                                            </section>
                                          </div>
                                      </div>
                                    </div>
                                </div>
                                <div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-zindex-0 margin-15px-991-colum">
                                    <div className="vc_column-inner tm-responsive-custom-12751771">
                                      <div className="wpb_wrapper">
                                          <hr/>
                                          <div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
                                            <section className="tm-vc_cta3-container tm-sepcolor-default">
                                                <div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
                                                  <div className="tm-vc_cta3_content-container">
                                                      <div className="tm-vc_cta3-content">
                                                        <header className=" tm-wrap">
                                                            <div className="tm-vc_cta3-headers tm-wrap-cell">
                                                              <h3 className="tm-custom-heading">Eligibility</h3>
                                                            </div>
                                                        </header>
                                                      </div>
                                                  </div>
                                                </div>
                                            </section>
                                          </div>
                                          <div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
                                            <div className="wpb_column tm-column-inner vc_column_container vc_col-sm-12 tm-zindex-0">
                                                <div className="vc_column-inner  ">
                                                  <div className="wpb_wrapper">
                                                      <ul className="m-0 tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
                                                        <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">The students must have completed their 10+2 with aggregate score of 50% and above to pursue BCA.</span></li>
                                                        <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">The students can be from any stream, but he/she must have passed 10th and 12th from a recognised educational board or university.</span></li>
                                                        <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">The students must have an age between 17 to 25 years to apply for the BCA course.</span></li>
                                                      </ul>
                                                  </div>
                                                </div>
                                            </div>
                                          </div>
                                      </div>
                                    </div>
                                </div>
                              </div>
                            </div>

                            <div className="vc_row-full-width vc_clearfix"></div>
                              <div data-vc-full-width="true" data-vc-full-width-init="false" id="" className="tm-row wpb_row vc_row-fluid vc_custom_1567684117259 tm-responsive-custom-55915355 vc_row-has-fill break-991-colum tm-zindex-0 tm-bgimage-position-right_bottom notification-section">
                                <div className="vc_row container">
                                  <div className="wpb_column tm-column vc_column_container vc_col-sm-6 tm-zindex-0 margin-15px-991-colum">
                                      <div className="vc_column-inner tm-responsive-custom-12751771">
                                        <div className="wpb_wrapper">
                                            <div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
                                              <section className="tm-vc_cta3-container tm-sepcolor-default">
                                                  <div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
                                                    <div className="tm-vc_cta3_content-container">
                                                        <div className="tm-vc_cta3-content">
                                                          <header className="tm-vc_cta3-content-header tm-wrap">
                                                              <div className="tm-vc_cta3-headers tm-wrap-cell">
                                                                <h4 className="tm-custom-heading ">Affiliations and Approval</h4>
                                                              </div>
                                                              <div className="heading-seperator"><span></span></div>
                                                          </header>
                                                        </div>
                                                    </div>
                                                  </div>
                                              </section>
                                            </div>
                                            <div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
                                              <div className="wpb_column tm-column-inner vc_column_container vc_col-sm-12 tm-zindex-0">
                                                  <div className="vc_column-inner  ">
                                                    <div className="wpb_wrapper">
                                                        <ul className="m-0 tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
                                                          <p>The BCA course is offered both in Government University and Deemed universities. Before understanding about the course and the institute in detail, it is very essential to understand the difference between Deemed university and Bangalore University (Government university) in Bangalore.</p>
                                                          <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content"><b>Deemed University</b></span></li>
                                                          <p>A Deemed University is quite simply an institution which provides high standards in a specific area of study. The deemed university is termed as deemed to be university by the central government upon the advice of the UGC. This status helps such institutions to enjoy the same benefits and privileges that are available to any other university in the country.</p>
                                                          <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content"><b>Bangalore University (Government)</b></span></li>
                                                          <p>Bangalore University (Government) is the university that is established or financed by the government of India and state government. The government universities are established or incorporated by or under a Central Act, a Provincial Act or a State Act. They are duly recognised by UGC by default.</p>
                                                        </ul>
                                                    </div>
                                                  </div>
                                              </div>
                                            </div>
                                        </div>
                                      </div>
                                  </div>
                                  <div className="wpb_column tm-column vc_column_container vc_col-sm-5 vc_col-sm-offset-1 tm-zindex-0 margin-15px-991-colum" id="notifications">
                                      <div className="vc_column-inner tm-responsive-custom-12751771">
                                        <div className="wpb_wrapper">
                                            <hr className="hidden-sm visible-xs"/>
                                            <div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
                                              <section className="tm-vc_cta3-container tm-sepcolor-default">
                                                  <div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
                                                    <div className="tm-vc_cta3_content-container">
                                                        <div className="tm-vc_cta3-content">
                                                          <header className="tm-vc_cta3-content-header tm-wrap">
                                                              <div className="tm-vc_cta3-headers tm-wrap-cell">
                                                                <h4 className="tm-custom-heading "><span className="bell fa fa-bell"></span> Notifications</h4>
                                                              </div>
                                                              <div className="heading-seperator"><span></span></div>
                                                          </header>
                                                        </div>
                                                    </div>
                                                  </div>
                                              </section>
                                            </div>
                                            <div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
                                              <div className="wpb_column tm-column-inner vc_column_container vc_col-sm-12 tm-zindex-0">
                                                  <div className="vc_column-inner  ">
                                                    <div className="wpb_wrapper">
                                                      <ul className="m-0 tm-recent-post-list scrollable-list">
                                                        {notifications && notifications.length > 0 && notifications.map((notification, index) => {
                                                          return (
                                                          <li className="tm-recent-post-list-li" key={`n${notification.id}`}>
                                                            <Link to={`/notifications/${notification.slug}`}>
                                                              <img width="150" height="150" src={`/storage/notifications/${notification.image2}`} className="attachment-thumbnail size-thumbnail wp-post-image lozad" alt={notification.title} src={`/storage/notifications/${notification.image2}`} data-loaded="true"/></Link>
                                                                <Link to={`/notifications/${notification.slug}`}>{notification.title}</Link>
                                                                <span className="post-date">{this.notificationDate(new Date(notification.updated_at))}</span>
                                                          </li>
                                                          );
                                                        })}
                                                      </ul>
                                                    </div>
                                                  </div>
                                              </div>
                                            </div>
                                        </div>
                                      </div>
                                  </div>
                                </div>
                            </div>

                            <div className="vc_row-full-width vc_clearfix"></div>
                              <div data-vc-full-width="true" data-vc-full-width-init="false" className="tm-row wpb_row vc_row-fluid vc_custom_1567684293976 tm-responsive-custom-10696870 vc_row-has-fill break-991-colum tm-zindex-0 tm-bgimage-position-left_center tm-bg tm-bgimage-yes">
                                <div className="tm-row-wrapper-bg-layer tm-bg-layer"></div>
                                <div className="vc_row container vc_row-o-equal-height vc_row-flex">
                                  <div className="wpb_column tm-column vc_column_container vc_col-sm-8 col-sm-offset-2 tm-overlap-row tm-span tm-left-span tm-zindex-2 margin-15px-991-colum">
                                      <div className="apply-now">
                                        <div className="widget">
                                            <h4 className="widget-title text-center">Apply now</h4>
                                            <p className="text-center">Apply now to get admission for BCA</p>
                                            <div className="widget-content">
                                              <form onSubmit={this.handleSubmit} id="home-page-middle-form">
                                                  <div className="row">
                                                    <div className="col-xs-12 col-sm-6">
                                                        <div className="text-block">
                                                          <input type="text" name="name" placeholder="Name *" required/> 
                                                        </div>
                                                    </div>
                                                    <div className="col-xs-12 col-sm-6">
                                                        <div className="text-block">
                                                          <input type="email" name="email" placeholder="Email *" required/> 
                                                        </div>
                                                    </div>
                                                    <div className="col-xs-12 col-sm-6">
                                                        <div className="text-block">
                                                          <input type="text" name="phone" value={mobile} onChange={e => this.updateMobile(e.target.value)} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}  className="EnquiryMobile" placeholder="Phone *" required /> 
                                                        </div>
                                                    </div>
                                                    <div className="col-xs-12 col-sm-6">
                                                        <div className="text-block">
                                                          <input type="text" name="course" placeholder="Course *" required/>
                                                          <input type="hidden" name="source" value="2"/> 
                                                        </div>
                                                    </div>
                                                    <div className="col-xs-12 col-sm-6">
                                                      <div className="text-block">
                                                        <Select options={countries} name="country"  placeholder="Select Country" required onChange={this.selectCountry} />
                                                      </div>
                                                    </div>
                                                    <div className="col-xs-12 col-sm-6">
                                                      <div className="text-block">
                                                        <Select options={states} value={stateValue} name="state" className="select-state" placeholder="Select State" required onChange={this.selectRegion} />
                                                      </div>
                                                    </div>
                                                    <div className="col-xs-12 col-sm-6 text-center hidden-xs">
                                                        <input onClick={this.resetForm} type="button" value="Reset" className="btn clear-btn"/> 
                                                    </div>
                                                    <div className="col-xs-12 col-sm-6 text-center">
                                                        <input type="submit" value="Submit" className="btn submit"/> 
                                                    </div>
                                                  </div>
                                              </form>
                                            </div>
                                        </div>
                                      </div>
                                  </div>
                                </div>
                            </div>

                            <div className="vc_row-full-width vc_clearfix"></div>
                              <div data-vc-full-width="true" data-vc-full-width-init="false" className="tm-row wpb_row vc_row-fluid vc_custom_1567684117259 tm-responsive-custom-55915355 vc_row-has-fill break-991-colum tm-zindex-0 tm-bgimage-position-right_bottom">
                                <div className="vc_row container">
                                  <div className="wpb_column tm-column vc_column_container vc_col-sm-6 tm-zindex-0 margin-15px-991-colum">
                                      <div className="vc_column-inner tm-responsive-custom-12751771">
                                        <div className="wpb_wrapper">
                                            <div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
                                              <section className="tm-vc_cta3-container tm-sepcolor-default">
                                                  <div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
                                                    <div className="tm-vc_cta3_content-container">
                                                        <div className="tm-vc_cta3-content">
                                                          <header className="tm-vc_cta3-content-header tm-wrap">
                                                              <div className="tm-vc_cta3-headers tm-wrap-cell">
                                                                <h4 className="tm-custom-heading ">Blogs</h4>
                                                              </div>
                                                              <div className="heading-seperator"><span></span></div>
                                                          </header>
                                                        </div>
                                                    </div>
                                                  </div>
                                              </section>
                                            </div>
                                            <div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
                                            {blogs && blogs.length > 0 && blogs.map((blogloop, index) => {
                                              return (
                                              <div className="wpb_column tm-column-inner vc_column_container vc_col-sm-6 tm-zindex-0" key={`b${index}`}>
                                                  <div className="vc_column-inner  ">
                                                    <div className="wpb_wrapper">
                                                        <ul className="m-0 tm-recent-post-list">
                                                        {blogloop && blogloop.length > 0 && blogloop.map((blog, index2) => {
                                                          return (
                                                          <li className="tm-recent-post-list-li" key={`bl${blog.id}`}>
                                                              <Link to={`/blogs/${blog.slug}`}><img width="150" height="150" src={`/storage/blogs/${blog.image2}`} className="attachment-thumbnail size-thumbnail wp-post-image" alt={blog.title}/></Link>
                                                              <Link to={`/blogs/${blog.slug}`}>{blog.title}</Link>
                                                              <span className="post-date">{this.notificationDate(new Date(blog.updated_at))}</span>
                                                          </li>
                                                          )
                                                        })}
                                                        </ul>
                                                    </div>
                                                  </div>
                                              </div>
                                              )
                                            })}
                                            </div>
                                        </div>
                                      </div>
                                  </div>
                                  <div className="wpb_column tm-column vc_column_container vc_col-sm-5 vc_col-sm-offset-1 tm-zindex-0 margin-15px-991-colum">
                                      <div className="vc_column-inner tm-responsive-custom-12751771">
                                        <div className="wpb_wrapper">
                                            <hr className="hidden-sm visible-xs"/>
                                            <div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
                                              <section className="tm-vc_cta3-container tm-sepcolor-default">
                                                  <div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
                                                    <div className="tm-vc_cta3_content-container">
                                                        <div className="tm-vc_cta3-content">
                                                          <header className="tm-vc_cta3-content-header tm-wrap">
                                                              <div className="tm-vc_cta3-headers tm-wrap-cell">
                                                                <h4 className="tm-custom-heading ">Similar Trending courses</h4>
                                                              </div>
                                                              <div className="heading-seperator"><span></span></div>
                                                          </header>
                                                        </div>
                                                    </div>
                                                  </div>
                                              </section>
                                            </div>
                                            <div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
                                              <div className="wpb_column tm-column-inner vc_column_container vc_col-sm-12 tm-zindex-0">
                                                  <div className="vc_column-inner  ">
                                                    <div className="wpb_wrapper">
                                                    <ul className="m-0 tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
                                                    {courses && courses.length > 0 && courses.map((course, index) => {
                                                      return (
                                                        <li key={`cs${course.id}`}>
                                                          <i className="tm-skincolor fa fa-arrow-circle-right"></i>  
                                                          <span className="tm-list-li-content">
                                                            <Link to={course.url} target="_blank">{course.course}</Link>
                                                          </span>
                                                        </li>
                                                      )
                                                    })}
                                                    </ul>
                                                    </div>
                                                  </div>
                                              </div>
                                              <div className="col-sm-10 text-center">
                                                  <hr/>
                                                  <a className="apply-btn" href="#enquiry" data-toggle="modal">Apply now</a> 
                                              </div>
                                            </div>
                                        </div>
                                      </div>
                                  </div>
                                </div>
                            </div>
                            <div className="vc_row-full-width vc_clearfix"></div>


                            <div data-vc-full-width="true" data-vc-full-width-init="false" className="tm-row wpb_row vc_row-fluid vc_custom_1567684293980 tm-responsive-custom-55915355 vc_row-has-fill break-991-colum tm-zindex-0 tm-bgimage-position-right_bottom">
                              <div className="vc_row container">
                                <div className="wpb_column tm-column vc_column_container vc_col-sm-8 tm-zindex-0 margin-15px-991-colum">
                                    <div className="vc_column-inner tm-responsive-custom-12751771">
                                      <div className="wpb_wrapper">
                                          <div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
                                            <section className="tm-vc_cta3-container tm-sepcolor-default">
                                                <div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
                                                  <div className="tm-vc_cta3_content-container">
                                                      <div className="tm-vc_cta3-content">
                                                        <header className="tm-vc_cta3-content-header tm-wrap">
                                                            <div className="tm-vc_cta3-headers tm-wrap-cell">
                                                              <h4 className="tm-custom-heading why-choose-bangalore">Why should one choose Bangalore city to study BCA?</h4>
                                                            </div>
                                                            <div className="heading-seperator"><span></span></div>
                                                        </header>
                                                      </div>
                                                  </div>
                                                </div>
                                            </section>
                                          </div>
                                          <div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
                                            <div className="wpb_column tm-column-inner vc_column_container vc_col-sm-12 tm-zindex-0">
                                                <div className="vc_column-inner  ">
                                                  <div className="wpb_wrapper">
                                                      <p className="why-choose-bangalore-content">Bangalore is considered as the hot destination for pursuing any course by the students. Since we know Bangalore is the “Silicon Valley of India” there numerous job companies from top MNCs to start-ups with thousands of job opportunities for the skilled students. The presence of top-notch companies of the world especially when it comes to tech companies are in need of skilled professionals each day. They always look for talented individuals and hire them for excellent salary packages. The job opportunities are ample in number in Bangalore due to the increased demand for skilled professionals by the IT organisations. Many global surveys claim that on an average 50,000 to 1 lakh BCA professionals are hired in Bangalore. So, there is a huge demand for skilled professionals in Bangalore.</p>
                                                      <p className="why-choose-bangalore-content"><b>Some of the reasons that make Bangalore the best destination for BCA students is:</b></p>
                                                      <ul className="why-choose-list tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591385700">
                                                        <li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">Top Notch Companies</span></li>
                                                        <li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">All the colleges have an excellent industry connection for secured placements</span></li>
                                                        <li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">Presence of number of colleges that are best in the world</span></li>
                                                        <li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">Cost Effective and economical</span></li>
                                                        <li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">Excellent Industry Exposure</span></li>
                                                        <li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">Bangalore is the Start-up Capital of India</span></li>
                                                        <li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">Cosmopolitan LifeStyle</span></li>
                                                        <li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">Multicultural Atmosphere</span></li>
                                                        <li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">Ideal climate for study</span></li>
                                                      </ul>
                                                  </div>
                                                </div>
                                            </div>
                                          </div>
                                      </div>
                                    </div>
                                </div>
                              </div>
                            </div>
                            <div className="vc_row-full-width vc_clearfix"></div>


                            <div data-vc-full-width="true" data-vc-full-width-init="false" className="tm-row wpb_row vc_row-fluid vc_custom_1567684117259 tm-responsive-custom-55915355 vc_row-has-fill break-991-colum tm-zindex-0 tm-bgimage-position-right_bottom">
                                <div className="vc_row container">
                                  <div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-zindex-0 margin-15px-991-colum">
                                      <div className="vc_column-inner tm-responsive-custom-12751771">
                                        <div className="wpb_wrapper">
                                            <div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
                                              <section className="tm-vc_cta3-container tm-sepcolor-default">
                                                  <div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
                                                    <div className="tm-vc_cta3_content-container">
                                                        <div className="tm-vc_cta3-content">
                                                          <header className="tm-vc_cta3-content-header tm-wrap">
                                                              <div className="tm-vc_cta3-headers tm-wrap-cell">
                                                                <h5 className="tm-custom-heading">Top 20+ BCA colleges in Bangalore</h5>
                                                              </div>
                                                              <div className="heading-seperator"><span></span></div>
                                                          </header>
                                                        </div>
                                                    </div>
                                                  </div>
                                              </section>
                                            </div>
                                            <div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
                                            {colleges && colleges.length > 0 && colleges.map((collegeloop, index) => {
                                              return (
                                              <div className="wpb_column tm-column-inner vc_column_container vc_col-sm-4 tm-zindex-0" key={`clg${index}`}>
                                                  <div className="vc_column-inner  ">
                                                    <div className="wpb_wrapper">
                                                        <ul className="m-0 tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
                                                        {collegeloop && collegeloop.length > 0 && collegeloop.map((college, index2) => {
                                                          return (
                                                            <li key={`clgi${college.id}`}>
                                                              <i className="tm-skincolor fa fa-arrow-circle-right"></i>
                                                              <span className="tm-list-li-content"><b>
                                                                <Link to={`/colleges/${college.slug}`}>{college.college}</Link></b></span>
                                                            </li>
                                                          )
                                                        })}
                                                        </ul>
                                                    </div>
                                                  </div>
                                              </div>
                                              )
                                            })}
                                              
                                            </div>
                                        </div>
                                      </div>
                                  </div>
                                </div>
                            </div>
                            <div className="vc_row-full-width vc_clearfix"></div>


                            <div data-vc-full-width="true" data-vc-full-width-init="false" className="tm-row wpb_row vc_row-fluid vc_custom_1567684117259 tm-responsive-custom-55915355 vc_row-has-fill break-991-colum tm-zindex-0 tm-bgimage-position-right_bottom notification-section">
                                <div className="vc_row container">
                                  <div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-zindex-0 margin-15px-991-colum">
                                      <div className="vc_column-inner tm-responsive-custom-12751771">
                                        <div className="wpb_wrapper">
                                            <div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
                                              <section className="tm-vc_cta3-container tm-sepcolor-default">
                                                  <div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
                                                    <div className="tm-vc_cta3_content-container">
                                                        <div className="tm-vc_cta3-content">
                                                          <header className="tm-vc_cta3-content-header tm-wrap">
                                                              <div className="tm-vc_cta3-headers tm-wrap-cell">
                                                                <h5 className="tm-custom-heading">Admission Process at Bangalore Colleges</h5>
                                                              </div>
                                                              <div className="heading-seperator"><span></span></div>
                                                          </header>
                                                        </div>
                                                    </div>
                                                  </div>
                                              </section>
                                            </div>
                                            <div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
                                              <div className="wpb_column tm-column-inner vc_column_container vc_col-sm-12 tm-zindex-0">
                                                  <div className="vc_column-inner  ">
                                                    <div className="wpb_wrapper">
                                                        {/* <!--<p>The students who have completed their PUC or 10+2 from recognised board with 50% aggregate can take admission to BCA courses in Bangalore. </p>--> */}
                                                        <p><b>Admission to BCA courses in Bangalore can be done by two modes of admission process they are:</b></p>
                                                        <ol>
                                                          <li>Merit Admission</li>
                                                          <li>Management Admission</li>
                                                        </ol>
                                                        <p>Let’s discuss them in detail</p>
                                                        <h6>1. Merit Admission Process</h6>
                                                        <p>Some of the top educational institutes in Bangalore that conduct institution-based entrance exams for the BCA courses and this mode is called as merit admission mode. The entrance exams are conducted to select the serious and hardworking students from the group of non-serious candidates to the available limited number of seats in the colleges. The qualified students are allotted the merit seats and are given admission, the fees is fairly nominal for merit admissions.</p>
                                                        <h6>2. Management Admission</h6>
                                                        <p>The students have another option of getting admission to BCA courses by direct admission process. Under this mode of admission, the students are given direct admission to their desired colleges under management quota. The direct admission students enjoy many special privileges like they are not required to apply for any institution-based entrance exam for getting admissions. They have an option to choose their desired colleges and book their seats even before the starting of the academic year.</p>
                                                    </div>
                                                  </div>
                                              </div>
                                            </div>
                                        </div>
                                      </div>
                                  </div>
                                </div>
                            </div>
                            <div className="vc_row-full-width vc_clearfix"></div>


                            <div data-vc-full-width="true" data-vc-full-width-init="false" className="tm-row wpb_row vc_row-fluid vc_custom_1567684117259 tm-responsive-custom-55915355 vc_row-has-fill break-991-colum tm-zindex-0 tm-bgimage-position-right_bottom">
                                <div className="vc_row container">
                                  <div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-zindex-0 margin-15px-991-colum">
                                      <div className="vc_column-inner tm-responsive-custom-12751771">
                                        <div className="wpb_wrapper">
                                            <div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
                                              <section className="tm-vc_cta3-container tm-sepcolor-default">
                                                  <div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
                                                    <div className="tm-vc_cta3_content-container">
                                                        <div className="tm-vc_cta3-content">
                                                          <header className="tm-vc_cta3-content-header tm-wrap">
                                                              <div className="tm-vc_cta3-headers tm-wrap-cell">
                                                                <h5 className="tm-custom-heading">Career Scope for BCA</h5>
                                                              </div>
                                                              <div className="heading-seperator"><span></span></div>
                                                          </header>
                                                        </div>
                                                    </div>
                                                  </div>
                                              </section>
                                            </div>
                                            <div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
                                              <div className="wpb_column tm-column-inner vc_column_container vc_col-sm-12 tm-zindex-0">
                                                  <div className="vc_column-inner  ">
                                                    <div className="wpb_wrapper">
                                                        <p>There is a huge scope for skilled Bachelor of Computer Applications (BCA) students in the job market. Doing a BCA course from one of the <b>top BCA colleges in Bangalore</b>, India would guarantee that one can get a genuine degree in computer applications from a presumed college. BCA is among those courses which hold extraordinary incentives in the resume of experts. Regardless of whether your field of specialisation is something different, one can do extraordinary with a degree in BCA.</p>
                                                        <p>BCA is a degree course in computer applications. With the rapid growth in the IT industry in India, the demand for computer professionals is increasing day by day. This increasing growth of the IT industry has created a lot of opportunities for computer graduates. BCA is one of the mainstream courses among the understudies who need to make their profession in the IT field. There is a critical development in the number of business opportunities for specialists of PC applications. With everything taken into account, there is a gigantic extension in the field of BCA. One can do work or can go for higher studies like MCA or MBA. The course gives a ton of chances to the understudies who are keen on the PC field and needs to work in the IT part as a software engineer or programming designer.</p>
                                                        <p>The interest of IT experts is expanding in India as well as abroad. After the completion of the course, the renowned IT organisations like IBM, Oracle, Infosys and Google. The course can fill in as a framework head. This field gives you sufficient chances to make your profession in the private segment as well as in the open segment.</p>
                                                        <div className="more-content career-scop-more-content">
                                                          <p>The BCA students can find ample number of job opportunities in various IT organisations. The skilled BCA students are hired technical positions across different organisations. There are a number of specific domains available in computer applications. The BCA students with specific specialisations are hired in specific domains and fields. The top BCA colleges in Bangalore have excellent industry connect and assure best of the placement opportunities in different sectors such as</p>
                                                          <ul className="m-0 tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
                                                              <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Government</span></li>
                                                              <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Private</span></li>
                                                              <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">MNCs</span></li>
                                                              <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Start-Ups</span></li>
                                                              <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Small and Medium Enterprises (SMEs)</span></li>
                                                              <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Global Companies</span></li>
                                                          </ul>
                                                        </div>
                                                        <a href="#" className="btn more more-btn">Read more</a>
                                                    </div>
                                                  </div>
                                              </div>
                                            </div>
                                        </div>
                                      </div>
                                  </div>
                                </div>
                            </div>
                            <div className="vc_row-full-width vc_clearfix"></div>


                            <div data-vc-full-width="true" data-vc-full-width-init="false" className="tm-row wpb_row vc_row-fluid vc_custom_1567684293977 tm-responsive-custom-10696870 vc_row-has-fill break-991-colum tm-zindex-0 tm-textcolor-white tm-bgimage-position-bottom_center tm-bg tm-bgimage-yes">
                                <div className="tm-row-wrapper-bg-layer tm-bg-layer"></div>
                                <div className="vc_row container vc_row-o-equal-height vc_row-flex">
                                  <div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-overlap-row tm-span tm-left-span tm-zindex-2 margin-15px-991-colum">
                                      <div className="vc_column-inner vc_custom_1567664250317 tm-responsive-custom-75919887">
                                        <div className="wpb_wrapper">
                                            <div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
                                              <section className="tm-vc_cta3-container tm-sepcolor-default">
                                                  <div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
                                                    <div className="tm-vc_cta3_content-container">
                                                        <div className="tm-vc_cta3-content">
                                                          <header className="tm-vc_cta3-content-header tm-wrap">
                                                              <div className="tm-vc_cta3-headers tm-wrap-cell">
                                                                <h5 className="tm-custom-heading ">BCA Placements and Salary Packages</h5>
                                                              </div>
                                                              <div className="heading-seperator"><span></span></div>
                                                          </header>
                                                        </div>
                                                    </div>
                                                  </div>
                                              </section>
                                            </div>
                                            <div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
                                              <div className="wpb_column tm-column-inner vc_column_container vc_col-sm-10 tm-zindex-0">
                                                  <div className="vc_column-inner  ">
                                                    <div className="wpb_wrapper study-list">
                                                        <p>The salary prospects and job opportunities differ from students to students. Basically, the placement opportunities depend on academic performance and skill set that the students possess with them.</p>
                                                        <p><b>Some of the factors that effect on the placement opportunities for the students are</b></p>
                                                        <div className="bz-collapse">
                                                          <div className="panel-group" id="bzCollapse" role="tablist" aria-multiselectable="true">
                                                              <div className="panel panel-default">
                                                                <div className="panel-heading" role="tab" id="headingOne">
                                                                    <h4 className="panel-title">
                                                                      <a role="button" data-toggle="collapse" data-parent="#bzCollapse" href="#collapseOpenOne" aria-expanded="true" aria-controls="collapseOpenOne">
                                                                      Academic Background, Performance and Record
                                                                      </a>
                                                                    </h4>
                                                                </div>
                                                                <div id="collapseOpenOne" className="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
                                                                    <div className="panel-body">
                                                                      <p>An excellent academic record always ensures excellent salary packages</p>
                                                                    </div>
                                                                </div>
                                                              </div>
                                                              <div className="panel panel-default">
                                                                <div className="panel-heading" role="tab" id="headingTwo">
                                                                    <h4 className="panel-title">
                                                                      <a className="collapsed" role="button" data-toggle="collapse" data-parent="#bzCollapse" href="#collapseOpenTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                                      Candidate Profile
                                                                      </a>
                                                                    </h4>
                                                                </div>
                                                                <div id="collapseOpenTwo" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                                                                    <div className="panel-body">
                                                                      <p>A smart, logical and alert candidates has potential to earn better salaries</p>
                                                                    </div>
                                                                </div>
                                                              </div>
                                                              <div className="panel panel-default">
                                                                <div className="panel-heading" role="tab" id="headingThree">
                                                                    <h4 className="panel-title">
                                                                      <a className="collapsed" role="button" data-toggle="collapse" data-parent="#bzCollapse" href="#collapseOpenThree" aria-expanded="false" aria-controls="collapseThree">
                                                                      Type of Business School
                                                                      </a>
                                                                    </h4>
                                                                </div>
                                                                <div id="collapseOpenThree" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                                                                    <div className="panel-body">
                                                                      <p>The BCA graduation if pursued in A+ category business school or institute ensures better salary packages</p>
                                                                    </div>
                                                                </div>
                                                              </div>
                                                              <div className="panel panel-default">
                                                                <div className="panel-heading" role="tab" id="headingFour">
                                                                    <h4 className="panel-title">
                                                                      <a className="collapsed" role="button" data-toggle="collapse" data-parent="#bzCollapse" href="#collapseOpenFour" aria-expanded="false" aria-controls="collapseFour">
                                                                      Additional Certifications
                                                                      </a>
                                                                    </h4>
                                                                </div>
                                                                <div id="collapseOpenFour" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFour">
                                                                    <div className="panel-body">
                                                                      <p>Additional Certifications related to latest subjects ensures that merit-practical exposure and skill enhancement that guarantees a high salary.</p>
                                                                    </div>
                                                                </div>
                                                              </div>
                                                              <div className="panel panel-default">
                                                                <div className="panel-heading" role="tab" id="headingFive">
                                                                    <h4 className="panel-title">
                                                                      <a className="collapsed" role="button" data-toggle="collapse" data-parent="#bzCollapse" href="#collapseOpenFive" aria-expanded="false" aria-controls="collapseFive">
                                                                      Government & Private-level job selection examinations and personal interviews
                                                                      </a>
                                                                    </h4>
                                                                </div>
                                                                <div id="collapseOpenFive" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFive">
                                                                    <div className="panel-body">
                                                                      <p>This proves that the candidates with high-performance records are eligible for high salary packages</p>
                                                                    </div>
                                                                </div>
                                                              </div>
                                                              <div className="panel panel-default">
                                                                <div className="panel-heading" role="tab" id="headingSix">
                                                                    <h4 className="panel-title">
                                                                      <a className="collapsed" role="button" data-toggle="collapse" data-parent="#bzCollapse" href="#collapseOpenSix" aria-expanded="false" aria-controls="collapseSix">
                                                                      Specialisations
                                                                      </a>
                                                                    </h4>
                                                                </div>
                                                                <div id="collapseOpenSix" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingSix">
                                                                    <div className="panel-body">
                                                                      <p>The colleges today offer different specialisations in BBA courses. The salaries also vary according to the specialisations in the industry</p>
                                                                    </div>
                                                                </div>
                                                              </div>
                                                              <div className="panel panel-default">
                                                                <div className="panel-heading" role="tab" id="headingSeven">
                                                                    <h4 className="panel-title">
                                                                      <a className="collapsed" role="button" data-toggle="collapse" data-parent="#bzCollapse" href="#collapseOpenSeven" aria-expanded="false" aria-controls="collapseSeven">
                                                                      Type of Organisation
                                                                      </a>
                                                                    </h4>
                                                                </div>
                                                                <div id="collapseOpenSeven" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingSeven">
                                                                    <div className="panel-body">
                                                                      <p>The salaries also depend upon the type of organisations, top MNCs and standard and reputed organisations with name and fame will offer good salary packages when compared to start-ups and small-scale industries</p>
                                                                    </div>
                                                                </div>
                                                              </div>
                                                              <div className="panel panel-default">
                                                                <div className="panel-heading" role="tab" id="headingEight">
                                                                    <h4 className="panel-title">
                                                                      <a className="collapsed" role="button" data-toggle="collapse" data-parent="#bzCollapse" href="#collapseOpenEight" aria-expanded="false" aria-controls="collapseEight">
                                                                      Skills
                                                                      </a>
                                                                    </h4>
                                                                </div>
                                                                <div id="collapseOpenEight" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingEight">
                                                                    <div className="panel-body">
                                                                      <p>The students with managerial skills like communication, logical, leadership, decision-making, planning, critical thinking, presentation skills and organising skills will also guarantee a very good salary</p>
                                                                    </div>
                                                                </div>
                                                              </div>
                                                              <div className="panel panel-default">
                                                                <div className="panel-heading" role="tab" id="headingNine">
                                                                    <h4 className="panel-title">
                                                                      <a className="collapsed" role="button" data-toggle="collapse" data-parent="#bzCollapse" href="#collapseOpenNine" aria-expanded="false" aria-controls="collapseNine">
                                                                      Adaptability to new technology
                                                                      </a>
                                                                    </h4>
                                                                </div>
                                                                <div id="collapseOpenNine" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingNine">
                                                                    <div className="panel-body">
                                                                      <p>The students with an ability to adapt and learn with new and advanced technology have an opportunity to get very good salary packages</p>
                                                                    </div>
                                                                </div>
                                                              </div>
                                                          </div>
                                                        </div>
                                                        <p>The salaries are also decent for BCA graduates according to their positions. The salary ranges from 5 lakhs to 15 lakhs per annum for skilled BCA graduates. The fresh BCA students will be earning around 4 lakhs to 6 lakhs per annum. The experienced professionals will be earning around 6 lakhs to 15 lakhs or even more. The salaries depend upon the location and recruiters.</p>
                                                        <p>The increased demand for management professionals and management leaders has resulted in availability of ample job opportunities for the BCA students. With global economic progress an increased number of international firms are interested in hiring qualified Indian BCA students.</p>
                                                    </div>
                                                  </div>
                                              </div>
                                            </div>
                                        </div>
                                      </div>
                                  </div>
                                </div>
                            </div>
                            <div className="vc_row-full-width vc_clearfix"></div>


                            <div data-vc-full-width="true" data-vc-full-width-init="false" className="tm-row wpb_row vc_row-fluid vc_custom_1567684117259 tm-responsive-custom-55915355 vc_row-has-fill break-991-colum tm-zindex-0 tm-bgimage-position-right_bottom" id="scholarships">
                                <div className="vc_row container">
                                  <div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-zindex-0 margin-15px-991-colum">
                                      <div className="vc_column-inner tm-responsive-custom-12751771">
                                        <div className="wpb_wrapper">
                                            <div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
                                              <section className="tm-vc_cta3-container tm-sepcolor-default">
                                                  <div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
                                                    <div className="tm-vc_cta3_content-container">
                                                        <div className="tm-vc_cta3-content">
                                                          <header className="tm-vc_cta3-content-header tm-wrap">
                                                              <div className="tm-vc_cta3-headers tm-wrap-cell">
                                                                <h5 className="tm-custom-heading">Scholarships for BCA</h5>
                                                              </div>
                                                              <div className="heading-seperator"><span></span></div>
                                                          </header>
                                                        </div>
                                                    </div>
                                                  </div>
                                              </section>
                                            </div>
                                            <div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
                                              <div className="wpb_column tm-column-inner vc_column_container vc_col-sm-12 tm-zindex-0 mt30">
                                                  <div className="vc_column-inner  ">
                                                    <div className="wpb_wrapper">
                                                        <h6>Types of Scholarships</h6>
                                                        <p>A BCA scholarship helps to ease the financial burden of candidates who want to pursue an BCA from a prestigious institute or a university. These BCA scholarships are available for Indian students who wish to pursue their dream of <b>studying in top BCA colleges</b> and universities in India and abroad.</p>
                                                        <p><b>Different types of BCA scholarships and financial aid are offered to Indian students who wish to pursue BCA in India and abroad:</b></p>
                                                        <ul className="m-0 tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
                                                          <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content"><b>Merit-based Scholarships - </b>based on excellent academic record</span></li>
                                                          <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content"><b>Merit-cum-Aptitude-based Scholarships - </b>based on excellent academic record and individual aptitude</span></li>
                                                          <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content"><b>Need-based Scholarships and Fellowships – </b>usually for candidates from low-income families</span></li>
                                                          <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content"><b>Gender-based Scholarships – </b>usually for female students</span></li>
                                                          <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content"><b>External Scholarships – </b>sponsored by renowned companies and organizations</span></li>
                                                          <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content"><b>International Scholarships – </b>provided by international universities and institutes</span></li>
                                                          <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content"><b>National Scholarships – </b>provided by national universities and B-Schools</span></li>
                                                          <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content"><b>State Scholarships – </b>provided by the state government</span></li>
                                                          <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content"><b>Private Scholarships – </b>provided by private universities and B-Schools</span></li>
                                                          <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content"><b>Fee Waivers - </b>usually a specific percentage, from 5% to 100%, of the total fees is waived off</span></li>
                                                          <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content"><b>Teaching Assistantship – </b>through part-time teaching jobs in the university</span></li>
                                                          <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content"><b>Summer Fellowships – </b>summer internship opportunities to obtain practical exposure and income</span></li>
                                                        </ul>
                                                    </div>
                                                  </div>
                                              </div>
                                              <div className="wpb_column tm-column-inner vc_column_container vc_col-sm-8 tm-zindex-0">
                                                  <div className="vc_column-inner  ">
                                                    <div className="wpb_wrapper">
                                                        <div className="bz-collapse">
                                                          <div className="panel-group" id="scholarshipsCollapse" role="tablist" aria-multiselectable="true">
                                                              <div className="panel panel-default">
                                                                <div className="panel-heading" role="tab" id="headingOneScholar">
                                                                    <h4 className="panel-title">
                                                                      <a role="button" data-toggle="collapse" data-parent="#scholarshipsCollapse" href="#collapseOpenOneScholar" aria-expanded="true" aria-controls="collapseOpenOneScholar">
                                                                      HOW TO AVAIL SCHOLARSHIPS?
                                                                      </a>
                                                                    </h4>
                                                                </div>
                                                                <div id="collapseOpenOneScholar" className="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOneScholar">
                                                                    <div className="panel-body">
                                                                      <p>It is important to begin research about scholarship application dates and deadlines at the earliest. Steps to avail a scholarship are:</p>
                                                                      <ol className="numbers-list">
                                                                          <li>There are two main categories of scholarships – Government and Private Scholarships. It is very important to choose which type of scholarship you want to avail before proceeding with applications.</li>
                                                                          <li>Research and monitor regularly about the announcement of scholarship exams notifications and details, application deadlines, and eligibility.</li>
                                                                          <li>Find out which Scholarships Exam eligibility criteria is matching with your academic qualifications.</li>
                                                                          <li>Fill the Application Form without missing the last date.</li>
                                                                          <li>Submit the requested important documents like previous academic transcripts, age and address proof, certificates of participation in sports and extra-curricular activities, proof of parent’s income (if applicable), statement of purpose, etc.</li>
                                                                          <li>Prepare well for competitive scholarship examinations and answer each question very carefully.</li>
                                                                          <li>Attend mock tests if possible.</li>
                                                                          <li>Some universities and institutes also conduct an additional Personal Interview round after the examination so it is good to be prepared.</li>
                                                                          <li>If you are applying to a university or college abroad, check for visa requirements.</li>
                                                                      </ol>
                                                                    </div>
                                                                </div>
                                                              </div>
                                                              <div className="panel panel-default">
                                                                <div className="panel-heading" role="tab" id="headingTwoScholar">
                                                                    <h4 className="panel-title">
                                                                      <a className="collapsed" role="button" data-toggle="collapse" data-parent="#scholarshipsCollapse" href="#collapseOpenTwoScholar" aria-expanded="false" aria-controls="collapseTwoScholar">
                                                                      HOW TO PREPARE FOR SCHOLARSHIP EXAMINATIONS?
                                                                      </a>
                                                                    </h4>
                                                                </div>
                                                                <div id="collapseOpenTwoScholar" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwoScholar">
                                                                    <div className="panel-body">
                                                                      <p>The process of securing a scholarship is very competitive because there are many applicants but limited scholarship offers. To get a scholarship the candidate should stand out from others. This can be achieved by:</p>
                                                                      <ul className="tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591385700">
                                                                          <li><i className="bz-skyblue fa fa-circle"></i> <span className="tm-list-li-content">Early organization, strategy, planning, time management, and preparation</span></li>
                                                                          <li><i className="bz-skyblue fa fa-circle"></i> <span className="tm-list-li-content">Review the mode, duration, syllabus, marking scheme, and examination pattern of the entrance exams</span></li>
                                                                          <li><i className="bz-skyblue fa fa-circle"></i> <span className="tm-list-li-content">Stay up-to-date with the latest news and current affairs of national and international significance</span></li>
                                                                          <li><i className="bz-skyblue fa fa-circle"></i> <span className="tm-list-li-content">Brush up on General Knowledge topics</span></li>
                                                                          <li><i className="bz-skyblue fa fa-circle"></i> <span className="tm-list-li-content">Prepare for Logical Reasoning questions with continuous practice</span></li>
                                                                          <li><i className="bz-skyblue fa fa-circle"></i> <span className="tm-list-li-content">Build Vocabulary, Comprehension, and Grammar</span></li>
                                                                          <li><i className="bz-skyblue fa fa-circle"></i> <span className="tm-list-li-content">Practice basic Mathematics and memorize formulae</span></li>
                                                                          <li><i className="bz-skyblue fa fa-circle"></i> <span className="tm-list-li-content">Hard work and determination are essential during preparation</span></li>
                                                                          <li><i className="bz-skyblue fa fa-circle"></i> <span className="tm-list-li-content">Avoid distractions like TV, music, and video games</span></li>
                                                                          <li><i className="bz-skyblue fa fa-circle"></i> <span className="tm-list-li-content">If additional assistance and guidance is required, join an education advisory centre like Galaxy Educational Services, one of Bangalore’s best academic advisory and counselling centres</span></li>
                                                                          <li><i className="bz-skyblue fa fa-circle"></i> <span className="tm-list-li-content">Prepare for the four main categories of questions usually asked in competitive examinations – Mathematical Skills, Intelligence & Critical/Logical Reasoning, Verbal Ability & Language Comprehension, and General Awareness</span></li>
                                                                      </ul>
                                                                    </div>
                                                                </div>
                                                              </div>
                                                          </div>
                                                        </div>
                                                    </div>
                                                  </div>
                                              </div>
                                              <div className="wpb_column tm-column-inner vc_column_container vc_col-sm-4 tm-zindex-0">
                                                  <div className="vc_column-inner  ">
                                                    <div className="wpb_wrapper scholarship-img">
                                                        <img src="/assets/img/scholarship.jpg" className="img-responsive lozad" alt="Scholarships"/>
                                                    </div>
                                                  </div>
                                              </div>
                                            </div>
                                        </div>
                                      </div>
                                  </div>
                                </div>
                                <div className="vc_row container">
                                  <div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-zindex-0 margin-15px-991-colum">
                                      <div className="vc_column-inner tm-responsive-custom-12751771">
                                        <div className="wpb_wrapper">
                                            <hr/>
                                            <div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
                                              <section className="tm-vc_cta3-container tm-sepcolor-default">
                                                  <div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
                                                    <div className="tm-vc_cta3_content-container">
                                                        <div className="tm-vc_cta3-content">
                                                          <header className="tm-vc_cta3-content-header tm-wrap">
                                                              <div className="tm-vc_cta3-headers tm-wrap-cell">
                                                                <h5 className="tm-custom-heading">Top BCA Recruitment Companies</h5>
                                                              </div>
                                                              <div className="heading-seperator"><span></span></div>
                                                          </header>
                                                        </div>
                                                    </div>
                                                  </div>
                                              </section>
                                            </div>
                                            <div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
                                              <div className="wpb_column tm-column-inner vc_column_container vc_col-sm-12 tm-zindex-0">
                                                  <div className="vc_column-inner">
                                                    <div className="wpb_wrapper">
                                                        <p>Many top MNCs hire BCA graduates at different positions especially in managerial positions.</p>
                                                        <p><b>Some of the top MNCs hiring BCA students are:</b></p>
                                                        <div className="row text-center brand-logos">
                                                          <div className="col-sm-2 col-xs-4"><img className="img-responsive lozad" src="/assets/img/brands/tcs.jpg" alt="tcs"/></div>
                                                          <div className="col-sm-2 col-xs-4"><img className="img-responsive lozad" src="/assets/img/brands/unilever.jpg" alt="unilever"/></div>
                                                          <div className="col-sm-2 col-xs-4"><img className="img-responsive lozad" src="/assets/img/brands/ibm.jpg" alt="ibm"/></div>
                                                          <div className="col-sm-2 col-xs-4"><img className="img-responsive lozad" src="/assets/img/brands/ey.jpg" alt="ey"/></div>
                                                          <div className="col-sm-2 col-xs-4"><img className="img-responsive lozad" src="/assets/img/brands/hp.jpg" alt="hp"/></div>
                                                          <div className="col-sm-2 col-xs-4"><img className="img-responsive lozad" src="/assets/img/brands/icici.jpg" alt="icici"/></div>
                                                          <div className="col-sm-2 col-xs-4"><img className="img-responsive lozad" src="/assets/img/brands/microsoft.jpg" alt="microsoft"/></div>
                                                          <div className="col-sm-2 col-xs-4"><img className="img-responsive lozad" src="/assets/img/brands/mckinsey.jpg" alt="mckinsey"/></div>
                                                          <div className="col-sm-2 col-xs-4"><img className="img-responsive lozad" src="/assets/img/brands/deloitte.jpg" alt="deloitte"/></div>
                                                          <div className="col-sm-2 col-xs-4"><img className="img-responsive lozad" src="/assets/img/brands/sony.jpg" alt="sony"/></div>
                                                          <div className="col-sm-2 col-xs-4"><img className="img-responsive lozad" src="/assets/img/brands/infosys.jpg" alt="infosys"/></div>
                                                          <div className="col-sm-2 col-xs-4"><img className="img-responsive lozad" src="/assets/img/brands/wipro.jpg" alt="wipro"/></div>
                                                        </div>
                                                    </div>
                                                  </div>
                                              </div>
                                            </div>
                                        </div>
                                      </div>
                                  </div>
                                </div>
                            </div>
                            <div className="vc_row-full-width vc_clearfix"></div>


                            <div data-vc-full-width="true" data-vc-full-width-init="false" className="tm-row wpb_row vc_row-fluid vc_custom_1567685146842 tm-zindex-0 tm-bgcolor-skincolor tm-bgimage-position-center_center get-direct-admission-area">
                                <div className="vc_row container">
                                  <div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-zindex-0">
                                      <div className="vc_column-inner  ">
                                        <div className="wpb_wrapper">
                                            <div className="wpb_raw_code wpb_content_element wpb_raw_html vc_custom_1568379925491 tm-textcolor-white">
                                              <div className="wpb_wrapper">
                                                  <h5 className="tm-custom-heading get-direct-admission-footer-text">
                                                  <i className="fa fa-graduation-cap" aria-hidden="true"></i> Get Direct BCA Admission Now, <u><a href="#enquiry" data-toggle="modal">Click here</a></u></h5>
                                              </div>
                                            </div>
                                        </div>
                                      </div>
                                  </div>
                                </div>
                            </div>
                            <div className="vc_row-full-width vc_clearfix"></div>


                         </div>
                      </article>
                  </main>
              </div>
            </div>
          </div>
        </div>
        );
    }
}

// export default Home;