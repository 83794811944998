import React from "react";
import {Link, useParams, useNavigate } from "react-router-dom";
import axios from 'axios'
import Swal from 'sweetalert2';
import { useState, useEffect, Component, useRef } from 'react';
import Helmet from 'react-helmet';
import parse from 'html-react-parser';
import Pagination from './Pagination';
import { ThreeDots } from 'react-loader-spinner'

export default function Blogs() {
    const navigate = useNavigate();
    const { id } = useParams();
    const params = useParams();
    const [blogs, setBlogs] = useState([])
    const [recentBlogs, setrecentBlogs] = useState([])
    const [notifications, setNotifications] = useState([])
    const mounted = useRef();
	const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(8);
	const [isLoading, setIsLoading] = useState(false)

    useEffect(()=>{
        fetchProduct();
    },[])
    const fetchProduct = async () => {
		setIsLoading(true)
        await axios.get(`/blogs-page-data`).then(({data})=>{
			setBlogs(data.blogs)
			setrecentBlogs(data.recentBlogs)
			setNotifications(data.notifications)
			setLoading(false)
			setIsLoading(false)
          // console.log(data);
        }).catch(({response:{data}})=>{
			setIsLoading(false)
			Swal.fire({
				text:'Error!.',
				icon:"error"
			})
        })
      }

     function blogDate(par){
        const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        const today = new Date(par);
        const month = months[today.getMonth()];
        const year = today.getFullYear();
        const date = today.getDate();
        return month+ ' ' +date+', ' +year;
      }

	  function truncate (str) {
		return str.length > 250 ? str.substring(0, 200) + "..." : str;
	  }

	  const indexOfLastRecord = currentPage * recordsPerPage;
	  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
	  const currentRecords = blogs.slice(indexOfFirstRecord, indexOfLastRecord);
	  const nPages = Math.ceil(blogs.length / recordsPerPage)

  return (
	!isLoading ? 
	blogs!==null?
	<div id="content-wrapper" className="site-content-wrapper">
	<Helmet>
		<title>Read Blogs Related to Bachelors of Computer Application | BCA Colleges in Bangalore</title>
		<meta name="description" content="Discover a collection of insightful and informative blogs on BCA. Stay updated on the latest trends and career opportunities in the field."/>
		<meta name="keywords" content="bca colleges ranking list, Top colleges blogs,bca colleges fees, bca course blogs, bca specialisations, placements, admission procedures, bca colleges blogs."/>
	</Helmet>
	  <div id="content" className="site-content container">
			<div id="content-inner" className="site-content-inner row multi-columns-row">
			  <div id="primary" className="content-area col-md-8 col-lg-8 col-xs-12 sub">
				 <main id="main" className="site-main">
						<div className="row">
							<div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-zindex-0 margin-15px-991-colum">
								<div className="vc_column-inner tm-responsive-custom-12751771">
									<div className="wpb_wrapper">
										<div className="themetechmount-boxes themetechmount-boxes-blog themetechmount-boxes-view-default themetechmount-boxes-col-three themetechmount-boxes-sortable-no themetechmount-boxes-textalign-center themetechmount-boxes-sortablebutton-">
										   <div className="themetechmount-boxes-inner themetechmount-boxes-blog-inner ">
											  <div className="row multi-columns-row themetechmount-boxes-row-wrapper">
												  {currentRecords && currentRecords.length > 0 && currentRecords.map((blog, index) => {
													return (
													<div className="tm-box-col-wrapper col-lg-6 col-sm-6 col-md-6 col-xs-12" key={`b${blog.id}`}>
													<article className="themetechmount-box themetechmount-box-blog themetechmount-box-topimage themetechmount-blogbox-format- ">
													   <div className="post-item">
														  <div className="themetechmount-box-content">
															 <div className="tm-featured-outer-wrapper tm-post-featured-outer-wrapper">
																<div className="tm-box-postdate">
																   <span className="tm-meta-line posted-on">
																   <span className="screen-reader-text tm-hide">Posted on </span>		
																   <time className="entry-date published">{blogDate(new Date(blog.updated_at))}</time>
																   </span>
																</div>
																<div className="tm-featured-wrapper tm-post-featured-wrapper tm-post-format-">
																	<Link to={`/blogs/${blog.slug}`}>
																		<img width="640" height="287" src={`/storage/blogs/${blog.image}`} className="attachment-themetechmount-img-blog-top size-themetechmount-img-blog-top wp-post-image" alt={blog.title}/>
																	</Link>
																</div>
															 </div>
															 <div className="themetechmount-box-desc">
																<div className="entry-header">
																   <div className="themetechmount-box-title">
																	  <h4><Link to={`/blogs/${blog.slug}`}>{blog.title}</Link></h4>
																   </div>
																</div>
																<div className="themetechmount-box-desc-text">
																{parse(truncate(blog.content))}
																</div>
															 </div>
														  </div>
													   </div>
													</article>
													 </div>
													);
												})}
											  </div>
											  <div className="clearfix"></div>
											   <Pagination
													nPages={nPages}
													currentPage={currentPage}
													setCurrentPage={setCurrentPage}
												/>
										   </div> 
										</div>		
									 </div>
								</div>
							</div>
						</div>

				 </main>
			  </div>
			  <aside id="sidebar-right" className="widget-area col-md-4 col-lg-4 col-xs-12 sidebar">
				 <aside id="themetechmount-recent-posts-5" className="widget-odd widget-9 widget themetechmount_widget_recent_entries">
					<h3 className="widget-title">Notifications</h3>
					<ul className="p-10 tm-recent-post-list">
					{notifications && notifications.length > 0 && notifications.map((notification, index) => {
						return (
						<li className="tm-recent-post-list-li" key={`n${notification.id}`}>
							<Link to={`/notifications/${notification.slug}`}>
								<img width="150" height="150" src={`/storage/notifications/${notification.image2}`} alt=""/>
							</Link>
							<Link to={`/notifications/${notification.slug}`}>{notification.title}</Link>
							<span className="post-date">{blogDate(new Date(notification.updated_at))}</span>
						</li>
						);
					})}
					</ul>
				 </aside>
				 <aside id="themetechmount-recent-posts-5" className="widget-odd widget-9 widget themetechmount_widget_recent_entries">
					<h3 className="widget-title" style={{backgroundColor: '#26272a'}}>Recent Blogs</h3>
					<ul className="p-10 tm-recent-post-list">
					{recentBlogs && recentBlogs.length > 0 && recentBlogs.map((recentBlog, index) => {
						return (
						<li className="tm-recent-post-list-li" key={`b${recentBlog.id}`}>
							<Link to={`/blogs/${recentBlog.slug}`}>
								<img width="150" height="150" src={`/storage/blogs/${recentBlog.image2}`} className="attachment-thumbnail size-thumbnail wp-post-image" alt={recentBlog.title}/>
							</Link>
							<Link to={`/blogs/${recentBlog.slug}`}>{recentBlog.title}</Link>
							<span className="post-date">{blogDate(new Date(recentBlog.updated_at))}</span>
						</li>
						)
					})}
					</ul>
				 </aside>
				 
				 <aside id="acadevo_contact_widget-4" className="widget-even widget-14 widget acadevo_contact_widget">
					<a className="hover" href="tel:+918095797575"><h3 className="widget-title"><i className="fa fa-phone" aria-hidden="true"></i> Helpline: +918095797575</h3></a>
				 </aside>
				 <aside id="acadevo_contact_widget-4" className="widget-even widget-14 widget acadevo_contact_widget">
					<a className="hover" href="https://bbacollegesinbangalore.com/" target="_blank"><h3 className="widget-title">Click to know about BBA Colleges in Bangalore <i className="fa fa-arrow-circle-right" aria-hidden="true"></i></h3></a>
				 </aside>
			  </aside>
		   </div>
	  </div>
	</div>
	:
	<h1> Can't connect to server ! </h1>
	:
	<div className="row">
		<div className="col-md-12 text-center">
			<ThreeDots
			visible={true}
			height="50"
			width="50"
			color="#black"
			radius="9"
			ariaLabel="three-dots-loading"
			wrapperStyle={{textAlign:'center'}}
			wrapperClass="loader"
			/>
		</div>
  </div>
  )
};

// export default NotificationDetails;