import React from "react";
import {Link} from "react-router-dom";
import axios from 'axios'
import Swal from 'sweetalert2';
import { useState, useEffect, Component } from 'react';
import Helmet from 'react-helmet';
import { HashLink } from 'react-router-hash-link';

export default class ContactUs extends Component {
    
    constructor(){
        super();
        this.state={
          blogs:[],
          notifications:[]
        }
      }
     
      async componentDidMount() {
        this.getList();
      }
      //API CALLS
      getList=()=>{
        this.getUser();
      }

      async getUser(){
        let usersData=await axios.get('/contact-page-data')
                        .then(res=>{
                        //   return res.data;
                          this.setState({
                            blogs:res.data.blogs,
                            notifications:res.data.notifications
                          },()=>{
                            // console.log(this.state)
                          })
                        })
                        .catch(err=>{
                          console.log(err);
                        })
      }

	  blogDate(par){
        const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        const today = new Date(par);
        const month = months[today.getMonth()];
        const year = today.getFullYear();
        const date = today.getDate();
        return month+ ' ' +date+', ' +year;
      }

      contactSubmit(event) {
        event.preventDefault();
        const formData = new FormData(event.target);
		jQuery("#overlay").fadeIn(300);　
        axios.post('/store-contact-enuiry', formData)
        .then(({data})=>{
            event.target.reset()
            if(data.status === 200){
                Swal.fire({
                    icon:"success",
                    text:data.message
                })
				setTimeout(function(){
                    jQuery("#overlay").fadeOut(300);
                },500);
			} else if(data.status === 201){
                Swal.fire({
                    text:'Please fill required fields.',
                    icon:"error"
                })
				setTimeout(function(){
                    jQuery("#overlay").fadeOut(300);
                },500);
            } else {
                Swal.fire({
                    text:'Error please try again later!',
                    icon:"error"
                })
				setTimeout(function(){
                    jQuery("#overlay").fadeOut(300);
                },500);
            }
        })
      }

    render(){
        const {blogs}=this.state;
        const {notifications}=this.state;
        return (
          <div id="content-wrapper" className="site-content-wrapper">
            <Helmet>
                <title>Contact us for BCA College Direct Admissions | Email | Call us</title>
                <meta name="description" content="For BCA admissions, reach out to us. Our team is here to assist you in the application process and provide information. Secure your spot for a promising career in computer applications."/>
                <meta name="keywords" content="Educational counselling centre in Bangalore, contact us for bca colleges,direct admissions, reach us for college admissions, contact us for bca course admissions."/>
            </Helmet>
              <div id="content" className="site-content container">
                    <div id="content-inner" className="site-content-inner row multi-columns-row">
					  <div id="primary" className="content-area col-md-8 col-lg-8 col-xs-12 sub">
						 <main id="main" className="site-main">
								<div className="row">
									<div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-zindex-0 margin-15px-991-colum">
										<div className="vc_column-inner tm-responsive-custom-12751771">
											<div className="wpb_wrapper">
												<div className="contact-form">
												<h3>Get in touch</h3>
												<div className="widget-content">
													<form onSubmit={this.contactSubmit}>
														<div className="row">
															<div className="col-xs-12">
																<div className="text-block">
																	<input type="text" name="name" placeholder="Name *" required/> </div>
															</div>
															<div className="col-xs-12">
																<div className="text-block">
																	<input type="email" name="email" placeholder="Email *" required/> </div>
															</div>
															<div className="col-xs-12">
																<div className="text-block">
																	<input type="text" name="phone" placeholder="Phone *" required/> </div>
															</div>
															<div className="col-xs-12">
																<div className="text-block">
																	<input type="text" name="place" placeholder="Place *" required/>
															</div>
															</div>
															<div className="col-xs-12">
																<div className="text-block">
																	<textarea name="messages" placeholder="Message" required></textarea>
															</div>
															</div>
															<div className="col-xs-12 text-center">
																<input type="submit" value="Submit" className="btn submit"/> </div>
														</div>
													</form>
												</div>
												</div>
												<hr/>
												<div className="contact-info">
													<h3>Contact info</h3>
													<div className="contact-box">
														<div className="">
															<div className="col-sm-12 col-md-6 single-address-box">
																<div className="single-address">
																	<i className="fa fa-phone"></i>
																	<h5>Phone</h5>
																	<p>+91 8095797575</p>
																</div>
															</div>  
															<div className="col-sm-12 col-md-6  single-address-box">
																<div className="single-address">
																	<i className="fa fa-envelope"></i>
																	<h5>Email</h5>
																	<p>info@bcacollegesinbangalore.com</p>
																</div>
															</div> 
															<div className="col-sm-12 col-md-12 single-address-box">
																<div className="single-address">
																	<i className="fa fa-map-marker"></i>
																	<h5>Location:</h5>
																	<p>No. 309, 3rd Floor, Brigade Gardens, Church Street, MG Road, Bangalore, Karnataka, India - 560001</p>
																</div>
																
															</div> 
															
														<div className="col-sm-12 col-md-12 single-address-box">
															<div className="single-address">
																<i className="fa fa-map-o"></i>
																<h5>Map:</h5>
																<div className="map">
																	<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.958446869434!2d77.60318071482205!3d12.974509590853978!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae17445270a0df%3A0x9707c29f3c98527a!2sBCA%20Colleges%20in%20Bangalore%20%7C%20Top%20BCA%20Colleges%20%7C%20Admission%20Guidance!5e0!3m2!1sen!2sin!4v1625637982589!5m2!1sen!2sin" width="600" height="450" style={{border:0}} allowFullScreen="" loading="lazy"></iframe>
																</div>
															</div>
															
														</div>
														</div>
													</div> 
												</div>
											</div>
										</div>
									</div>
								</div>

						 </main>
					  </div>
					  
					  <aside id="sidebar-right" className="widget-area col-md-4 col-lg-4 col-xs-12 sidebar">
						<aside id="themetechmount-recent-posts-5" className="widget-odd widget-9 widget themetechmount_widget_recent_entries">
							<h3 className="widget-title" style={{backgroundColor: '#26272a'}}>Blogs</h3>
							<ul className="p-10 tm-recent-post-list">
							{blogs && blogs.length > 0 && blogs.map((blog, index) => {
                                return (
                            	<li className="tm-recent-post-list-li" key={`b${blog.id}`}>
									<Link to={`/blogs/${blog.slug}`}>
										<img width="150" height="150" src={`/storage/blogs/${blog.image2}`} className="attachment-thumbnail size-thumbnail wp-post-image" alt={blog.title}/>
									</Link>
                                    <Link to={`/blogs/${blog.slug}`}>{blog.title}</Link>
									<span className="post-date">{this.blogDate(new Date(blog.updated_at))}</span>
								</li>
								)
							})}
							</ul>
						 </aside>
						
						 
						 <aside id="acadevo_contact_widget-4" className="widget-even widget-14 widget acadevo_contact_widget">
						 <HashLink smooth className="hover" to="/#scholarships"><h3 className="widget-title">SCHOLARSHIPS <i className="fa fa-arrow-circle-right" aria-hidden="true"></i></h3></HashLink>
						 </aside>	
						 <aside id="themetechmount-recent-posts-5" className="widget-odd widget-9 widget themetechmount_widget_recent_entries">
							<h3 className="widget-title" style={{backgroundColor: '#26272a'}}>Similar Trending courses</h3>
							<ul className="p-10 similar-crs tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
								<li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">General Nursing and Midwifery (GNM)</span></li>
								<li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">Post Basic (PB) BSc Nursing</span></li>
								<li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">BSc Nursing</span></li>
								<li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">MSc Nursing</span></li>
							</ul>
						 </aside>
						 <aside id="themetechmount-recent-posts-5" className="widget-odd widget-9 widget themetechmount_widget_recent_entries">
							<h3 className="widget-title">Notifications</h3>
							<ul className="p-10 tm-recent-post-list">
							{notifications && notifications.length > 0 && notifications.map((notification, index) => {
                                return (
                                <li className="tm-recent-post-list-li" key={`n${notification.id}`}>
									<Link to={`/notifications/${notification.slug}`}>
                                        <img width="150" height="150" src={`/storage/notifications/${notification.image2}`} alt=""/>
                                    </Link>
                                    <Link to={`/notifications/${notification.slug}`}>{notification.title}</Link>
                                    <span className="post-date">{this.blogDate(new Date(notification.updated_at))}</span>
                                </li>
								);
							})}
							</ul>
						 </aside>
					  </aside>
				   </div>
              </div>
        </div>
        );
    }
}

// export default Home;